html,body {height: 100%;}
body {padding-top: 118px;}
@media (max-width:1198.98px) {body {padding-top: 110px;}
_p {font-size: 1rem;}
}
@media (max-width:767.98px) {body {padding-top: 74px; font-size: .95rem !important;}}
@media (max-width:344.98px) {body {padding-top: 75px; font-size: .95rem !important;}}
.container-1920 {max-width: 1920px;width: 100%;margin: auto;}
.z-index-2 {z-index:2}
.admin-contanier img {max-width: 100%; height: auto}
@media (max-width:1199px) {.btn {font-size: .90rem !important;}
.offcanvas-end-nav {width: 320px !important;}}

/* Sweep To Right */
.btn-primary{display:inline-block;vertical-align:middle;-webkit-transform:perspective(1px) translateZ(0);transform:perspective(1px) translateZ(0);box-shadow:0 0 1px transparent;position:relative;-webkit-transition-property:color;transition-property:color;-webkit-transition-duration:.3s;transition-duration:.3s;overflow:hidden;background:#004f9f;color:#fff; border:0px !important}
.btn-primary:before{content:"";position:absolute;z-index:-1;top:0;left:0;right:0;bottom:0;background:#1a3155;-webkit-transform:scaleX(0);transform:scaleX(0);-webkit-transform-origin:0 50%;transform-origin:0 50%;-webkit-transition-property:transform;transition-property:transform;-webkit-transition-duration:.3s;transition-duration:.3s;-webkit-transition-timing-function:ease-out;transition-timing-function:ease-out}
.btn-primary:active,.btn-primary:focus,.btn-primary:hover{background:#004f9f;color:#fff!important}
.btn-primary:active:before,.btn-primary:focus:before,.btn-primary:hover:before{-webkit-transform:scaleX(1);transform:scaleX(1)}

.btn-dark{display:inline-block;vertical-align:middle;-webkit-transform:perspective(1px) translateZ(0);transform:perspective(1px) translateZ(0);box-shadow:0 0 1px transparent;position:relative;-webkit-transition-property:color;transition-property:color;-webkit-transition-duration:.3s;transition-duration:.3s;overflow:hidden;background:rgba(0,0,0,.65);color:#fff; border:1px solid rgba(255,255,255,.75) !important}
.btn-dark:before{content:"";position:absolute;z-index:-1;top:0;left:0;right:0;bottom:0;background:#000;-webkit-transform:scaleX(0);transform:scaleX(0);-webkit-transform-origin:0 50%;transform-origin:0 50%;-webkit-transition-property:transform;transition-property:transform;-webkit-transition-duration:.3s;transition-duration:.3s;-webkit-transition-timing-function:ease-out;transition-timing-function:ease-out}
.btn-dark:active,.btn-dark:focus,.btn-dark:hover{background:rgba(0,0,0,.65);color:#fff!important}
.btn-dark:active:before,.btn-dark:focus:before,.btn-dark:hover:before{-webkit-transform:scaleX(1);transform:scaleX(1)}

/*** Header ***/
.header-logo {margin: .75rem 0rem; text-decoration: none;}
.header-logo .caption h2 {font-size:1.7rem; font-weight: 500; font-family: 'Oswald', sans-serif; line-height: 1.3;}
.header-logo .caption {font-size:1.125rem; font-family: 'Oswald', sans-serif; color: #fff;}
a .header-logo .caption {color: inherit;}
.logo {transition: all ease-in 0.4s;}
.logo-caption {transition: all ease-in 0.4s; }
.bg-header-home {background: rgba(255, 255, 255, 1);box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);}
.bg-header-inner {background: rgba(255, 255, 255, 1);transition: all ease-in 0.4s;padding: 0rem 0rem;}
.bg-header-inner .navbar-nav .nav-link {color: #000}
.navbar-nav {font-size: 1rem;}
.navbar-nav .nav-link {color: #000;}
.top-nav .nav-item {padding: 0rem .9rem 0rem 0.65rem !important; margin-top:1rem}
.top-nav .nav-link {position: relative; overflow: hidden; padding:.55rem 0.3rem .55rem !important; }
.top-bt-nav .nav-item {margin: 0rem .7rem !important;}
@media (max-width:1124px) {.top-nav .nav-item {padding: 0rem 0rem 0rem 0.65rem !important; margin-top:1rem}}
@media (max-width:1365px) {.top-bt-nav .nav-item {margin: 0rem .4rem !important;}}
.top-bt-nav .nav-link {color: #000; padding:2rem 0.3rem .85rem !important; transition: all ease-in 0.4s; text-transform: uppercase; font-weight: 400;}
.navbar-nav .show > .nav-link {color:#000 !important}
.dropdown-menu {border-radius: 0px !important; --bs-dropdown-spacer:-0px; border-top:0; border:0; background: #f5f5f5;}
.dropdown-item:hover {background-color: transparent !important; color:#004f9f }
.dropdown-item a {display: block; text-decoration: none; color: inherit;}
.bg-search-panel .modal-content {background-color: rgba(0, 0, 0, 0.1);}
.bg-search-panel .modal-header > button {background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;}

.top-bt-nav .dropdown-menu {border-bottom:.3rem solid #004f9f;}
.top-bt-nav .dropdown-item a {font-size: .90rem;}
.top-bt-nav .dropdown-item a {padding: 0.4rem 1.5rem;}
.top-bt-nav .dropdown-item {vertical-align: top;padding:0px;background-color: transparent !important;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-ms-transition: all 0.3s;-o-transition: all 0.3s;transition: all 0.3s;}
.top-bt-nav .dropdown .dropdown-menu {margin-top: 0; -webkit-animation: fadeInLeft 500ms ease; animation: fadeInLeft 600m ease-in-out;}

@media (max-width:1200px) {.top-bt-nav .dropdown-item {white-space:normal !important; font-size: .85rem;}
.top-bt-nav .dropdown-item a {padding: 0.4rem 1rem;}
}

@media (min-width:1200px) {
  .top-nav-collapse .header-logo .caption h2 {font-size:1.3rem; font-family: 'Oswald', sans-serif; line-height: 1.4; color: #000;}
  .top-nav-collapse .header-logo .caption {font-size:1rem; font-family: 'Oswald', sans-serif; color: #000;}
  .top-nav-collapse a .header-logo .caption {color: inherit;}
  .top-nav-collapse .navbar-nav .nav-link {color: #000}
  .top-nav-collapse .navbar-nav .nav-link:hover {color: #000 !important}
  .top-nav-collapse {background: rgba(255, 255, 255, 1);box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);}
  .top-nav {margin-top:-1rem}
  .top-nav li {position: relative;}
  .top-nav li::after {content:'|'; position: absolute; color:#000; top:0px; top: 0px; right:0px;bottom: 0;display: flex;align-items: center; transition: all ease-in 0.4s;}
  .top-nav-collapse .top-nav li::after {content:'|'; position: absolute; color:#000; top:0px; top: 0px; right:0px;bottom: 0;display: flex;align-items: center;}
  .top-nav li:last-child::after{content: none;}
  .nav-item {position: relative;}
  .bg-header-home .top-bt-nav .nav-link:before, .bg-header-home .top-nav .nav-link:before {content: '';position: absolute;bottom:0px;left: 0;width: 0;-webkit-transform: translateX(0%);transform: translateX(0%);height:3px;background:#000;-webkit-transition: .35s;transition: .35s;}
  .top-nav-collapse .top-bt-nav .nav-link:before, .top-nav-collapse .top-nav .nav-link:before {content: '';position: absolute;bottom:0px;left: 0;width: 0;-webkit-transform: translateX(0%);transform: translateX(0%);height:3px;background:#000;-webkit-transition: .35s;transition: .35s;}
  .nav-item:hover .nav-link:before, .nav-item.active .nav-link:before, .bg-header-home .top-bt-nav .active .nav-link:before {width: 100%;}
  .top-nav-collapse .top-bt-nav .nav-link {color: #000; _padding:1.3rem 0.3rem 1rem !important;}
  .top-nav-collapse .navbar-nav .show > .nav-link {color:#000 !important}
  .top-nav-collapse .top-nav .nav-link:hover a.btn-primary {color:#464e57 !important}
  .navbar-nav .nav-link:hover {color: #000}
  ul.top-bt-nav li:nth-child(7) .nav-item .dropdown-menu {left:auto !important; right:0px !important;}
  }

@media (min-width:1400px) {
    .header-logo {_margin: 1.3rem 0rem 1.1rem; text-decoration: none;}
    .logo {transition: all ease-in 0.4s; }
    .logo-caption {transition: all ease-in 0.4s;}
    .header-logo .caption h2 {font-size:1.6rem; font-weight: 500; font-family: 'Oswald', sans-serif; line-height: 1.4; transition: all ease-in 0.3s;}
    .header-logo .caption {font-size:1.15rem; font-weight: 300; font-family: 'Oswald', sans-serif; color: #fff; transition: all ease-in 0.4s;}
    a .header-logo .caption {color: inherit;}
    }

@media (max-width:1198.98px) {
      .bg-header-home {background: rgba(255, 255, 255, 1);transition: all ease-in 0.4s;box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); position:fixed}
      .top-bt-nav .nav-link {color: #000; border-bottom:1px solid #eee; padding:1rem 0.3rem 1rem !important; }
      .top-nav .nav-link {color: #000;}
      .top-nav-collapse .navbar-nav .nav-link {color: #000}
      .top-nav-collapse .navbar-nav .nav-link:hover {color: #000}
      .top-nav-collapse {background: rgba(255, 255, 255, 1);box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);}
      .top-nav {margin-top:0rem}
      .top-nav li {position: relative;}
      .top-nav li::after {content:'|'; position: absolute; color:#fff; top:0px; top: 0px; right:0px;bottom: 0;display: flex;align-items: center;}
      .top-nav li:last-child::after{content: none;}
      .nav-item {position: relative;}
      .bg-header-home .top-bt-nav .nav-link:before {content: '';position: absolute;bottom:-5px;left: 0;width: 0;-webkit-transform: translateX(0%);transform: translateX(0%);height:2px;background:var(--bs-light);-webkit-transition: .35s;transition: .35s;}
      .top-nav-collapse .top-bt-nav .nav-link:before {content: '';position: absolute;bottom:-5px;left: 0;width: 0;-webkit-transform: translateX(0%);transform: translateX(0%);height:2px;background:#000;-webkit-transition: .35s;transition: .35s;}
      .nav-item:hover .nav-link:before, .nav-item.active .nav-link:before {width: 100%;}
      .top-nav-collapse .navbar-nav .show > .nav-link {color:#000 !important}
      .navbar-nav .show > .nav-link {color:#004f9f !important}
      .dropdown-menu {border-radius: 0px !important; --bs-dropdown-spacer:-.3px; border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);}
      .top-nav .nav-item {margin-top:0}
      .top-nav .nav-item button.btn-primary {background:none !important; margin-top:0}
      }    

@media (max-width:767.98px) {
    .logo {transition: all ease-in 0.4s; height: 50px;}
    .logo-caption {height: 50px;}
     
    .header-logo .caption h2, .top-nav-collapse .header-logo .caption h2 {font-size:.85rem; font-weight: 500; font-family: 'Oswald', sans-serif; line-height: 1.3; transition: all ease-in 0.3s;}
    .header-logo .caption, .top-nav-collapse .header-logo .caption {font-size:.75rem; font-weight: 300; font-family: 'Oswald', sans-serif; color: #000; transition: all ease-in 0.4s;}
    a .header-logo .caption,  .top-nav-collapse a .header-logo .caption {color: inherit;}
      
    .top-nav-collapse .logo {height: 50px;}
    .top-nav-collapse .logo-caption {height: 50px;}
}
      
@media (max-width:344.98px) {
        .logo {transition: all ease-in 0.4s;}
        /* .logo-caption {height: 40px;} */
        .header-logo .caption h2, .top-nav-collapse .header-logo .caption h2 {font-size:.70rem; font-weight: 500; font-family: 'Oswald', sans-serif; line-height: 1.28; transition: all ease-in 0.3s;}
        .header-logo .caption,  .top-nav-collapse .header-logo .caption {font-size:.60rem; font-weight: 300; font-family: 'Oswald', sans-serif; color: #000; transition: all ease-in 0.4s;}
        a .header-logo .caption,  .top-nav-collapse a .header-logo .caption {color: inherit;}
        .top-nav-collapse .logo {height: 30px;}
        .top-nav-collapse .logo-caption {height: 35px;}
        
      }

/*** Visual ***/
.slider-caption {position: absolute;top: 0;left: 0;right:0;text-align: left;display: flex;align-items: start;justify-content: center;flex-direction: column; z-index: 9;}
.slider-caption .caption-container {width:55%}
.slider-caption .h1 {opacity: 0; --webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}
.slider-caption small {opacity: 0; color: #74c6d4; --webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}
.slider-caption a {opacity: 0; -webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}

.active .slider-caption .h1 {opacity:1; --webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}
.active .slider-caption small {opacity:1; color: #74c6d4; --webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}
.active .slider-caption a {opacity:1; -webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}

.carousel-control-next, .carousel-control-prev {display: none;}

.visual-container-1920 {max-width: 1920px;width: 100%;margin: auto;margin-top: -65px;}
@media (max-width:1200px) {
  .visual-container-1920 {max-width: 1920px;width: 100%;margin: auto;margin-top: 0px;}
  .slider-caption small {font-size: 110%;}
}
@media (min-width:1023px) {.slider-caption .caption-container {width:50%}}

@media (max-width:1400px) and (min-width:1280px) {
  .slider-caption .caption-container {width:40%}
  .slider-caption .h1 {font-size: 2.1rem;}
}

@media (min-width:1500px) {.slider-caption .caption-container {width:40%}}

/*** Visual Inside ***/
.bg-visual-inside {background-size: cover; width: 100%;}
.bg-visual-inside img {width: 100%;}
.visual-text {position: absolute; bottom:20%; left:0px; right:0px; color:#fff}

@media (max-width:767.98px){
  .bg-visual-inside {min-height: 90px;}
  .visual-text {position: absolute; bottom:5%; left:0px; right:0px; color:#fff}
}

@media (max-width:1440px){.visual-text {position: absolute; bottom:5%; left:0px; right:0px; color:#fff}}

/*** Ticker ***/
.bg-ticker {background: #ebebeb;}

/*** Border Box ***/
.border-box {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
.border-box::before {content: ''; border:1px solid #004f9f; border-width: 1px 1px 1px 1px; width: 100%; position: absolute; top:0px; bottom:0px; height:100%;}

.border-box-1 {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
.border-box-1::before {content: ''; border:0px solid #004f9f; border-width: 0px 0px 0px 0px; left:0rem; right:0rem; position: absolute; top:0px; bottom:0px; height:100%;}

.border-box-inside-rht {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
.border-box-inside-rht::before {content: ''; border:0px solid #004f9f; border-width: 0px 0px 0px 0px; width: 100%; position: absolute; top:0px; bottom:0px; height:100%;}

.border-box-inside-lft {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
.border-box-inside-lft::after {content: ''; border:0px solid #004f9f; border-width: 0px 0px 0px 0px; width: 100%; position: absolute; top:0px; bottom:0px; height:100%; right:0}

.border-1 {border: 0px !important;}

@media (min-width:768px){
  .border-box-1 {border:0px solid #004f9f; position: relative;}
  .border-box-1::before {content: ''; border:1px solid #004f9f; width: 100%; position: absolute; top:0px; bottom:0px; height:100%;}
  .border-box-2 {border:0px solid #004f9f; position: relative;}
  .border-box-2::before {content: ''; border:1px solid #004f9f; width: 100%; position: absolute; top:0px; bottom:0px; height:100%;}
  .border-1 {border: 0px solid #004f9f !important;}
}

@media (min-width:992.98px){
  .border-box::before {width: 125%;}
  .border-box-inside-rht::before {width: 165%;}
  .border-box-inside-lft::after {width: 165%;}

  .border-box-1 {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
  .border-box-1::before {content: ''; border:1px solid #004f9f; border-width: 1px 1px 1px 1px; width: 94%; position: absolute; top:0px; bottom:0px; height:100%;}
  .border-1 {border: 1px solid #004f9f !important;}

  .border-box-2 {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
  .border-box-2::before {content: ''; border:1px solid #004f9f; border-width: 1px 1px 1px 1px; right:0px; width: 94%; position: absolute; top:0px; bottom:0px; height:100%;}
}

@media (min-width:1198.98px){
.border-box::before {width: 125%;}
.border-box-inside-rht::before {width: 144%;}
.border-box-inside-lft::after {width: 144%;}
}

/*** Header ***/
.hd {position: relative; color:#000;display:flex;align-items: center;}
/* .hd:before {content:''; position: relative;display: inline-block; width: 100px; height: 5px; background: #004f9f; } */
.hd span, .hd1 span {color:#8a8c8f}
/* .hd .h1, .hd1 .h1 {padding-left:1rem;padding-right:1rem;} */
.hd1 {position: relative; color:#000;display:flex;align-items: center;justify-content: center;}
/* .hd1:before, .hd1:after {content:''; position: relative;display: inline-block; width: 100px; height: 5px; background: #004f9f; } */
.heading span {color:#8a8c8f}

/*** Stats ***/
.stats ul {display: flex; justify-content: center;}
.stats ul li {border-right:1px solid #aaa; padding: 0px 5.3%; display: inline;}
.stats ul li:first-child {padding: 0px 5.3% 0px 0; }
.stats ul li:last-child {border-right:0px solid #3a4046; padding: 0px 0px 0px 5.3%;}

@media (max-width:992px) {
.stats ul {display: block; justify-content: center;}
.stats ul li {display: inline-block; width: 50%;; margin-bottom: 10px; vertical-align: top;}
.stats ul li:first-child {padding: 0px 5.3% 20px 5.3%; }
.stats ul li:nth-child(2) {border-right:0px solid #3a4046;}
.stats ul li:last-child {border-right:0px solid #3a4046; padding: 0px 0px 0px 5.3%;}
}

/*** News ***/
.news .pic {min-width:230px;}
.news .btnlink {min-width:30px; color:#fff; font-size: 1.8rem; transition: all ease-in 0.3s; }
.news .btnlink:hover {background-color: #000 !important;}
/* .news a {color:inherit; transition: all ease-in 0.3s; text-decoration: none;}*/
.news h5:hover  {color:#004f9f} 


/*** Events ***/
.events .dates {min-width:135px; background: #004f9f; margin-left: -1rem; padding: 1rem; text-align: center; color:rgba(255,255,255,.85); transition: all ease-in 0.3s;}
.events .dates h2 {line-height: normal;}
.events .btn-link {color:#3a4046; text-decoration: none;}

/* .events:hover .dates {min-width:135px; background: #000; margin-left: -1rem; text-align: center; color:rgba(255,255,255,.85)} */
.events a { transition: all ease-in 0.3s; text-decoration: none;}
/* .events:hover a {color:#004f9f} */
.events:hover a.border {border:1px solid #000 !important}
@media (max-width:767.98px) {
  .events .dates {min-width:135px; background: #004f9f; margin-left: 0rem; padding:1.2rem 1.5rem; text-align: center; color:rgba(255,255,255,.85); transition: all ease-in 0.3s;}
  .events:hover .dates {min-width:135px; background: #000; margin-left: 0rem; text-align: center; color:rgba(255,255,255,.85)}
}

/*** Parallax ***/
/* .react-parallax {height:600px} */
.react-parallax-bgimage {background-size: cover; background-position: left;}

/*** Research Areas ***/
.btn-5 {
    display: inline-block;
    position: relative;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 20px 10px;
  }
  @media (max-width:1279px) {
    .btn-5 {
      display: inline-block;
      position: relative;
      background: none;
      border: none;
      border:1px solid #004f9f;
      cursor: pointer;
      padding: 10px 5px;
    }
  }
  figure {
    display: block;
    padding: 20px 20px;
    margin: 0px;
  }
  .btn-5::before,
  .btn-5::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #004f9f;
}

figure::before,
figure::after {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #004f9f;
}

@media (min-width:1279px) {
.btn-5:hover::before,
.btn-5:hover::after {
  width: 100%;
}
.btn-5:hover figure::before,
.btn-5:hover figure::after {
  height: 100%;
}
.btn-5::after {
    left: 0;
    bottom: 0;
    transition-duration: 0.4s;
  }
  .btn-5 figure::after {
    right: 0;
    top: 0;
    transition-duration: 0.4s;
  }
  .btn-5::before {
    right: 0;
    top: 0;
    transition-duration: 0.4s;
  }
  .btn-5 figure::before {
    left: 0;
    bottom: 0;
    transition-duration: 0.4s;
  }
}

/*** Academic Programs ***/
.academics-info {padding-left:120px;}
.collaboration-info {padding-right:120px;}
.academics-info .list-group-item, .collaboration-info .list-group-item {background: transparent; padding: 0.858rem 0; margin: 0rem; border-bottom: 1px solid #909090;}

.academics-info a {color:inherit; text-decoration: none; transition-duration: 0.4s;}
.academics-info a:hover {color:#004f9f; text-decoration: none;}

.collaboration-info a {color:inherit; text-decoration: none; transition-duration: 0.4s;}
.collaboration-info a:hover {color:#004f9f; text-decoration: none;}

@media (max-width:1279px){
.bg-academics {background-image: none; background: #dddbd0;}
.academics-info {padding-left:0px;}
.bg-collaboration {background-image: none; background: #bdd1da;}
}

@media (max-width:767.98px){
.academics-info ul li {font-size: 1rem;} 
}

@media (min-width:1280px){
  .bg-academics {background: url('../../../public/images/bg-academic-programe.jpg') no-repeat center top !important; background-size: cover !important;}
  .bg-collaboration-main {background: #bdd1da;}
  .bg-collaboration {background:#bdd1da url('../../../public/images/bg-collaboration.jpg') no-repeat center center !important; background-size: cover !important; min-height: 550px;}
}

/*** Achievements ***/
.bg-achievements {background: url('../../../public/images/bg-achievement.jpg') no-repeat right top; background-size: cover; min-height: 686px;}
@media (max-width:1199.98px){
  .bg-achievements {background-image: none; background: #f6efdb; min-height: auto;}
  .academics-info {padding-left:0px;}
  .collaboration-info {padding-right:0px;}
}

.bg-about {background: url('../../../public/images/bg-about.jpg') no-repeat right center; background-size: cover; }
/*** Achievements ***/
.bg-achievements {background: url('../../../public/images/bg-achievement.jpg') no-repeat right top; background-size: cover; min-height: 686px;}
@media (max-width:1199.98px){
  .bg-achievements {background-image: none; background: #f6efdb; min-height: auto;}
  .academics-info {padding-left:0px;}
  .bg-about {background-image: none; background: #d0e1e8; min-height: auto;}
}

.sp-caption-container {padding: 0px; margin: 0px;}
.sp-buttons {
  position: absolute;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  bottom: 10px;
}

.sp-layer {max-width: 1400px;margin: 0 auto;left: 0px;right: 0px;}



.list {display:block; padding:0px;}
.list li {padding:0px; margin:0px 0px 15px 40px;}

.list ul {display:block; padding:0px;}
.list ul li {padding:0px; margin:0px 0px 10px 40px;}

/*** Breadcrumb ***/
.breadcrumb-panel {display: flex; justify-content: center; padding-top: 1rem; padding-left: .5rem; padding-left: .5rem; border-top:1px solid #dee2e6}
.breadcrumb {display: flex; justify-content: center; }

/*** Footer ***/
footer {background: #3a4046;color: rgba(255,255,255,.85); font-weight: 300; font-size: 1rem !important;}
footer h6 {font-size: 1rem;}
footer .list-group-item {background: transparent;color: #fff;padding: .2rem 0px;border: none}
.bg-ftr {background: #004f9f;}

footer a {color: inherit; text-decoration: none;}
footer a:hover {color: inherit; text-decoration: underline;}

/*** Social Media ***/
ul.social_media{margin:0px;padding:5px 20px 5px 00px;width:100%;text-align:left}
ul.social_media li{display:inline-block;list-style-type:none;list-style-image:none;padding:0px;margin:0 10px 10px 0px;border:none;vertical-align:top;}
ul.social_media li a{display:block;font-size:16px;transition:none .6s ease-in-out;-webkit-transition:none .6s ease-in-out;-moz-transition:none .6s ease-in-out;-o-transition:none .6s ease-in-out;-ms-transition:none .6s ease-in-out;}
ul.social_media li a:hover{display:block;font-size:16px;}
ul.social_media a{padding:0px;margin:0px;font-weight:300;color:#fff;width:50px;height:50px;line-height:50px;text-align:center;}
ul.social_media .icon-24 {width: 24px; height: 24px;}
ul.social_media a:hover{color:#fff}
.facebook, .google, .linkedin, .instagram, .twitter, .youtube {transition:all .6s ease-out;-webkit-transition:all .6s ease-out;-moz-transition:all .6s ease-out;-o-transition:all .6s ease-out;-ms-transition:all .6s ease-out;border-radius:0;}
.facebook {background:#fff; transition-duration: 0.3s;}
.facebook svg {fill:#254fa7;  transition-duration: 0.3s;}
.facebook:hover{background:#254fa7; border-radius:5px;}
.facebook:hover svg {fill:#fff;}
.twitter {background:#fff;  transition-duration: 0.3s;}
.twitter svg {fill:#0f1419; transition-duration: 0.3s;}
.twitter:hover{background:#0f1419; border-radius:5px;}
.twitter:hover svg {fill:#fff;}
.linkedin {background:#fff; transition-duration: 0.3s;}
.linkedin svg {fill:#254fa7;  transition-duration: 0.3s;}
.linkedin:hover{background:#0069d6; border-radius:5px;}
.linkedin:hover svg {fill:#fff;}
.instagram {background:#3e739d;}
.instagram:hover{background:#3e739d; border-radius:5px;}
.youtube {background:#fff; transition-duration: 0.3s;}
.youtube svg {fill:#ff0000; transition-duration: 0.3s;}
.youtube:hover{background:#ff0000; border-radius:5px;}
.youtube:hover svg {fill:#fff;}
.whatsapp {background:#fff; transition-duration: 0.3s;}
.whatsapp svg {fill:#59CE72; transition-duration: 0.3s;}
.whatsapp:hover{background:#59CE72; border-radius:5px;}
.whatsapp:hover svg {fill:#fff;}
.linkedin {background:#fff; transition-duration: 0.3s;}
.linkedin svg {fill:#0072b1; transition-duration: 0.3s;}
.linkedin:hover{background:#0072b1; border-radius:5px;}
.linkedin:hover svg {fill:#fff;}
.instagram {background:#fff; transition-duration: 0.3s;}
.instagram svg {fill: #c13584; transition-duration: 0.3s;}
.instagram:hover{background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d); border-radius:5px;}
.instagram:hover svg {fill:#fff;}

.pinterest {background:#fff; transition-duration: 0.3s;}
.pinterest svg {fill:#E60023; transition-duration: 0.3s;}
.pinterest:hover{background:#E60023; border-radius:5px;}
.pinterest:hover svg {fill:#fff;}

.blogger {background:#fff; transition-duration: 0.3s;}
.blogger svg {fill:#ff4f00; transition-duration: 0.3s;}
.blogger:hover{background:#ff4f00; border-radius:5px;}
.blogger:hover svg {fill:#fff;}


ul.social-media-top li{display:inline-block;list-style-type:none;list-style-image:none;padding:0px;margin:0 5px 4px 0px;border:none;vertical-align:top;}
ul.social-media-top a{padding:0px;margin:0px;font-weight:300;color:#fff;width:35px;height:35px;line-height:35px;text-align:center;}
ul.social-media-top .icon-24 {width: 18px; height: 18px;}
@media (max-width:1199px) {
ul.social_media .fa{padding:0px;margin:0px;font-weight:300;color:#fff;width:50px;height:50px;line-height:53px;text-align:center; font-size:25px;}
}

/*** Offcanvas ***/
.offcanvas-toggle2{display:none;}
.offcanvas-toggle{padding:12px 10px;background:transparent;border:0px;border-radius:0px;display:none;cursor:pointer;outline:none!important;}
.offcanvas-toggle .icon-bar{background:#004f9f;display:block;width:22px;height:2px;border-radius:1px;transition:all .25s ease-in-out}
.offcanvas-toggle .icon-bar + .icon-bar{margin-top:4px;}
.offcanvas-toggle .icon-bar:nth-child(3){width:12px;}
.offcanvas-toggle:hover .icon-bar:nth-child(3){width:22px;}
.offcanvas-toggle.is-open .icon-bar:nth-child(1){-webkit-transform:rotate(45deg) translate(5px, 4px);transform:rotate(45deg) translate(5px, 4px)}.offcanvas-toggle.is-open .icon-bar:nth-child(2){opacity:0}.offcanvas-toggle.is-open .icon-bar:nth-child(3){width:22px;-webkit-transform:rotate(-45deg) translate(4px, -4px);transform:rotate(-45deg) translate(4px, -4px)}
div#offcanvas-overlay{position:fixed;display:none;height:100%;width:100%;top:0;left:0;cursor:pointer;background-color:rgba(0,0,0,.7);visibility:hidden;opacity:0;z-index:9;-webkit-backface-visibility:hidden;backface-visibility:hidden;-webkit-transition:opacity .4s 0s,visibility 0s .4s;-moz-transition:opacity .4s 0s,visibility 0s .4s;transition:opacity .4s 0s,visibility 0s .4s}
.open-menu div#offcanvas-overlay{opacity:1;display:block;visibility:visible;-webkit-transition:opacity .4s 0s,visibility 0s 0s;-moz-transition:opacity .4s 0s,visibility 0s 0s;transition:opacity .4s 0s,visibility 0s 0s}
@media (max-width:1199.98px){
.offcanvas-toggle, .offcanvas-toggle2{display:inline-block ; padding: 10px;}
.offcanvas-collapse, .offcanvas-collapse2{position:fixed;top:0;bottom:0;left:-250px;max-width:250px;width:100%;padding-right:0;padding-left:0;overflow-y:auto;visibility:hidden;background-color:#000;transition-timing-function:ease-in-out;transition-duration:.3s;transition-property:left, visibility;z-index:9999;}
.offcanvas-collapse.open, .offcanvas-collapse2.open{left:0;visibility:visible;}
.offcanvas-toggle2 {cursor: pointer;box-shadow: 2px 2px 0px rgba(0,0,0,0.8);width:100%;margin-top:-15px;margin-bottom:30px}
}


/*** People Tabs ***/
.people-nav a {text-transform: none!important;letter-spacing: 0rem !important;font-size: 1.1rem!important;color: #000!important;padding: 12px 10px!important; font-weight: 400 !important; text-decoration: none;border-bottom: 5px solid #ebebeb;}
.people-nav a.active {color: #204d99!important; font-weight: 600 !important; border-bottom: 5px solid #204d99;}
@media (min-width:1198.98px){
  .people-nav a {font-size: 1.4rem!important;  padding: 12px 24px!important; }
  .people-nav a.active {color: #204d99!important; font-weight: 600 !important;}
  .people-nav a {border-bottom: 5px solid #ebebeb;}
  .people-nav a.active {border-bottom: 5px solid #204d99;}
  .people-content .col img {filter: grayscale(100); transition:all .3s ease-in-out}
  .people-content .col:hover img {filter: grayscale(0);}
}

.people .css-b3u9hy {border-bottom: none!important;}
.people .MuiTabs-indicator {background-color: #204d99!important;height: 5px!important;}

.people .MuiTab-root {text-transform: none!important;letter-spacing: 0rem !important;font-size: 1.1rem!important;color: #000!important;padding: 12px 10px!important; font-weight: 400 !important;}
.people .Mui-selected {color: #204d99!important; font-weight: 600 !important;}
.people .css-19kzrtu {padding:0 !important}
.people .MuiTypography-root .col a {text-decoration: none;color: #204d99;text-transform: uppercase;font-weight:500;display: flex;align-items: center;}
.people .MuiTypography-root .col a svg {stroke-width:1;}

.bg-picacademics {background-repeat: no-repeat;}

@media (min-width:1198.98px){
.people .MuiTypography-root {font-size: 1.125rem!important; }
.people .MuiTabs-flexContainer {border-bottom: 5px solid #ebebeb;}
.people .MuiTab-root {text-transform: none!important;letter-spacing: 0rem !important;font-size: 1.4rem!important;color: #000!important;padding: 12px 24px!important; font-weight: 400 !important;}
.people .Mui-selected {color: #204d99!important; font-weight: 600 !important;}
.people .MuiTypography-root .col img {filter: grayscale(100); transition:all .3s ease-in-out}
.people .MuiTypography-root .col:hover img {filter: grayscale(0);}


.shiny .bg-pic-newsletter{overflow:hidden;display:flex; align-items: center; position:relative;max-width: 265px; margin: auto;}
.shiny .bg-pic-newsletter .bg-img {position: absolute;left: 0;right: 0;display: flex; align-items: center; justify-content: center;}
.shiny .bg-pic{overflow:hidden;display:inline-block;position:relative;margin-bottom:.5rem;}
.shiny .bg-pic:after{content:'';position:absolute;top:0;left:-20%;width:0;height:100%;background-color:rgba(255,255,255,0.4);-webkit-transform:skew(20deg);transform:skew(20deg);}
.shiny .bg-pic-academics{overflow:hidden;_display:inline-block;position:relative;margin-bottom:.5rem;}
.shiny .bg-pic-academics:after{content:'';position:absolute;top:0;left:-20%;width:0;height:100%;background-color:rgba(255,255,255,0.4);-webkit-transform:skew(20deg);transform:skew(20deg);}

.shiny:hover img{-webkit-transform:scale(1.1);-ms-transform:scale(1.1);-moz-transform:scale(1.1);transform:scale(1.1);}
.shiny img, .shiny:hover .bg-pic:after, .shiny:hover .bg-pic-academics:after{-webkit-transition:all 0.8s ease-in-out;-moz-transition:all 0.8s ease-in-out;transition:all 0.8s ease-in-out;}
.shiny:hover .bg-pic:after, .shiny:hover .bg-pic-academics:after{width:150%;background-color:rgba(255,255,255,0);}

.bg-picacademics {background-position: center center; background-size: cover; background-repeat: no-repeat; -webkit-transition:all 0.8s ease-in-out;-moz-transition:all 0.8s ease-in-out;transition:all 0.8s ease-in-out;}
.shiny:hover .bg-picacademics {background-position: center center; background-size: cover; background-repeat: no-repeat; -webkit-transform:scale(1.1);-ms-transform:scale(1.1);-moz-transform:scale(1.1);transform:scale(1.1);}
}

.newsletter a, .reports a {color: inherit; text-decoration: none; transition:all .3s ease-in-out}
.newsletter a:hover, .reports a:hover {color: #004f9f; text-decoration: none;}

.marquee-container .overlay {background: transparent !important; --gradient-color:transparent !important}
.marquee-container a {color:inherit; text-decoration: none; transition:all .3s ease-in-out}
.marquee-container a:hover {color:#004f9f; text-decoration: none; transition:all .3s ease-in-out}

.admission-update-list ul {border-radius: 0px; margin-bottom: 1.5rem;}
.admission-update-list ul li {border-width: 1px 1px 1px 1px !important;}
.admission-update-list ul li:nth-child(1) {min-width: 120px; font-weight: 600;}
.admission-update-list ul li:nth-child(2) {width: auto; }
.admission-update-list ul li:nth-child(3) {min-width: 210px;}

@media (max-width:767px) {
.admission-update-list ul li {border-width: 1px 1px 1px 1px !important;}
.admission-update-list ul li:nth-child(1) {min-width: 120px; border-bottom:0px; font-weight: 600;}
.admission-update-list ul li:nth-child(2) {width: auto; border-bottom:0px; border-top:0px;}
.admission-update-list ul li:nth-child(3) {min-width: 210px; border-top:0px;}
}

@media (min-width:768px) {
.admission-update-list ul {border-radius: 0px;  margin-bottom: 0rem;}   
.admission-update-list ul li {border-width: 0px 0px 1px 0px !important; padding: .75rem 0rem;}
.admission-update-list ul li:nth-child(2) {width: auto; padding-right: 2rem; padding-left: 2rem;}
}

@media (max-width:767px) {
  .publications-table table thead tr th:nth-child(2) {min-width: 150px !important;}
  .publications-table table thead tr th:nth-child(1) {min-width: 200px !important;}
  .publications-table table thead tr th:nth-child(3) {min-width: auto !important;}
  
  
}

@media (min-width:768px) {
  .publications-table table thead tr th:nth-child(2) {min-width: 230px !important;}
  .publications-table table thead tr th:nth-child(1) {min-width: 200px !important;}
  .publications-table table thead tr th:nth-child(3) {min-width: auto !important;}
  .publications-table table thead tr th:nth-child(4) {min-width: 85px !important;}
  .publications-table table tbody tr td:nth-child(4) {text-align: center;}
}

@media (min-width:1024px) {
  .publications-table table thead tr th:nth-child(2) {min-width: 230px !important;}
  .publications-table table thead tr th:nth-child(1) {min-width: 485px !important; padding-right: 25px !important;}
  .publications-table table thead tr th:nth-child(3) {min-width: auto !important;}
  .publications-table table thead tr th:nth-child(4) {min-width: 85px !important;}
  .publications-table table tbody tr td:nth-child(4) {text-align: center;}
}

.academics-popup .btn-close {box-sizing: content-box;width: 1em;height: 1em;padding: .25em .25em;color: #fff;background: transparent url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e) center/1em auto no-repeat;opacity:.8;}
.publication-content table tr td, .publication-content table tr th {font-size: 1rem !important;}

.publication-content .table {position: relative;}
.publication-box {
  position: relative;
  _background: #fff !important;
}
.publication-box {
  background-image: linear-gradient(to bottom left, transparent 50%, #fff 50%), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  background-size: 25px 25px, 100% 100%, 100% 100%;
  background-position: 100% 0%, -25px 0%, 100% 25px;
  background-repeat: no-repeat;
}
.publications a {color: inherit; text-decoration: none !important;}
.publications a:hover {color: #004f9f;}

.publication-details a {color: #004f9f; text-decoration: underline !important;}
/* .publication-details a:hover {color: #004f9f; text-decoration: underline !important;} */

.filled {
  background-image: linear-gradient(#004f9f, #004f9f), linear-gradient(#004f9f, #004f9f), linear-gradient(#004f9f, #004f9f), linear-gradient(#004f9f, #004f9f), linear-gradient(to bottom left, transparent calc(50% - 1px), #004f9f calc(50% - 1px), #004f9f calc(50% + 1px), #fff calc(50% + 1px)), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  background-size: 1px 100%, 1px 100%, 100% 1px, 100% 1px, 20px 20px, 100% 100%, 100% 100%;
  background-position: 0% 0%, 100% 20px, -20px 0%, 0px 100%, 100% 0%, -20px 0%, 100% 20px;
}

.publication-box:before {
  content: "";
  position: absolute;
  top: 0px;
  right: -.10px;
  border-width: 0 20px 20px 0;
  border-style: solid;
  border-color: #fff transparent;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  box-shadow: 0px 1px 1px rgba(0,0,0,0), -1px 1px 0px rgba(0,79,159,1);
}

/* .research a {color:#004f9f}
.research:hover a {color:inherit} */

@media (max-width:1200px) {
  .player-wrapper {margin-top: 0px;}
}

.player-wrapper {position: relative;top:0px;height: 0px;left:0px;right:0px;min-height: 100%;margin-top: -118px;overflow: hidden;}
.react-player {position: absolute;height: 108%;width: 100%;}
.bg-video { position: absolute; z-index: 2;}
.admision-container {word-wrap: break-word;}

.accordion {
  --bs-accordion-color: hsl(210, 11%, 15%);
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}
.accordion-button {background-color: transparent !important;}
.accordion-button:focus {box-shadow: none;}
.accordion-button:not(.collapsed) {background:#004f9f !important; color: #fff; box-shadow: none;}
.accordion-button:not(.collapsed)::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-body {background: #f5f5f5;}
.people {background: #fff !important; padding-left: 0px !important; padding-right: 0px !important;}
.editor table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color)
}
.editor figure.table, .editor table {padding: 0px !important; margin: 0px !important; overflow: auto;}
.editor table>:not(caption)>*>* {
  padding: .5rem .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.editor table>tbody {
  vertical-align: inherit
}

.editor table>tbody tr:first-child {
  background-color: #eee;
}

.editor table>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.editor table>thead {
  vertical-align: bottom
}

#sitemap a, #sitemap h6 a {text-decoration: none;}
#sitemap a:hover, #sitemap h6 a:hover {text-decoration: underline;}

.scroll-to-top {position:fixed !important;bottom:0;right:0 !important;z-index:9999}
.scroll-to-top svg {fill: #fff !important;}
.scroll-to-top {height: auto; width: auto; line-height: normal !important; color: inherit !important; text-align: center;}
.scroll-to-top {color:#fff!important;text-decoration:none !important;background:#004f9f !important;padding:0px;width:40px !important;height:30px !important;line-height:20px !important;border-radius:100px !important;text-align:center !important;bottom:50px !important;border-top-right-radius:0 !important;border-bottom-right-radius:0 !important}
.scroll-to-top:hover{color:#fff !important;text-decoration:none !important;background:#053d78 !important}

/* left navbar for people */
.left-navbar {display: block; padding: 0; margin: 0;}
.left-navbar li {display: block; padding: 0; margin: 0; font-size: 0.9375rem !important;}
.left-navbar li a {padding: 8px 0px; display: block;  -webkit-transition: all 300ms linear;-moz-transition: all 300ms linear;-o-transition: all 300ms linear;-ms-transition: all 300ms linear; text-decoration: none;}
.left-navbar li a:hover {padding: 8px 12px; color: #0854a1; text-decoration: none;}
.left-navbar li a.active {padding: 8px 12px; color: #000; }

.btn-link {font-weight: 400;color: #01509f !important;text-decoration: underline;}

@media (max-width: 767px) {
.left-navbar li {display: block;} 
.left-navbar li a {color:#000; padding: 8px 12px; }
.left-navbar li a:hover {padding: 8px 12px; color: #0854a1;}
.left-navbar li a.active {padding: 8px 12px; color: #000; font-weight: 700; background: transparent !important;}

.people-nav a {border-bottom: 1px solid #ebebeb;}
.people-nav a.active {border-bottom: 1px solid #204d99;}
}


/* @ckeditor/ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-selector-caption-text);
  background-color: var(--ck-color-selector-caption-background);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: .4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized {
  table-layout: fixed;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
  overflow: hidden;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
  overflow-wrap: break-word;
  position: relative;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: '';
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: .3em .6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* @ckeditor/ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  position: relative;
  margin-bottom: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content[dir=rtl] .todo-list .todo-list__label > input {
left: 0;
margin-right: 0;
right: -25px;
margin-left: -15px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc( var(--ck-todo-list-checkmark-size) / 3 );
  top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
  transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label.todo-list__label_without-description input[type=checkbox] {
  position: absolute;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > input,
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input {
  cursor: pointer;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > input:hover::before, .ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input:hover::before {
  box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.1);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content[dir=rtl] .todo-list .todo-list__label > span[contenteditable=false] > input {
  left: 0;
  margin-right: 0;
  right: -25px;
  margin-left: -15px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc( var(--ck-todo-list-checkmark-size) / 3 );
  top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
  transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label.todo-list__label_without-description input[type=checkbox] {
  position: absolute;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
  height: auto;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content img.image_resized {
  height: auto;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* @ckeditor/ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: var(--ck-color-image-caption-text);
  background-color: var(--ck-color-image-caption-background);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol {
  list-style-type: decimal;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol {
  list-style-type: lower-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol {
  list-style-type: lower-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol {
  list-style-type: upper-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol ol {
  list-style-type: upper-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul {
  list-style-type: disc;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul {
  list-style-type: circle;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul {
  list-style-type: square;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul ul {
  list-style-type: square;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: .15em;
  border-radius: 2px;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: .7em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: .85em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
/* @ckeditor/ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
@media print {
  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
      padding: 0;
  }
  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
      display: none;
  }
}

@media not all and (min-resolution:.001dpcm) {
  @media {
      .gap-3 * + * {
          margin-left: 1rem;
      }
  }
}

.cookie-wrapper {
  background-color: #333131 !important;
  padding: 0.5rem;
  font-size: 1rem;
  z-index: 1;
}


.cookie-wrapper .btn {
  background-color: #004f9f !important;
  color: #fff !important;
  font-size: .875rem !important;
  padding: 0.25rem 1rem !important;
}
.cookie-wrapper .btn:hover {
  background: #fff !important;
  color: #000 !important;
}

@media (max-width:767.98px) {
  .cookie-content {flex: auto !important; font-size: 80% !important;}
  }